@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding: calc(var(--header-height) + 86px) 0 86px;

  &__content {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .square {
    text-align: center;
    margin: 0 auto 16px;
    font-weight: 500;

    &::before {
      left: 44.5%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  h1 {
    color: var( --color-brown-800);
    margin: 0 auto 48px;
  }

  h2 {
    font-weight: var(--fw-600);
    margin: 0 0 24px;
    font-size: 25px;
  }

  p {
    color: var(--color-grey-400);

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  a {
    color: var( --color-brown-800);
    text-decoration: underline;
    font-weight: var(--fw-700);
  }

  ul {
    padding-top: 24px;
    margin: 0 0 24px;
  }

  li {
    color: var(--color-grey-400);
    font-size: 18px;
    margin-bottom: 30px;

    span {
      font-weight: 800;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

  @include media(tablet) {
    padding: calc(var(--header-height) + 40px) 0 40px;
  }

  @include media(mobile-m) {

    h1 {
      font-size: 44px;
    }

  }

  @include media(mobile-l) {

    h1 {
      margin-bottom: 30px;
      text-align: center;
    }

  }
}