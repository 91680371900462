@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;


.services {
  padding: 86px 0;

  &__inner {
    display: flex;
    gap: 50px;
    align-items: flex-start;
  }

  h2 {
    text-transform: none;
    font-size: 25px;
    font-weight: 600;
    max-width: 688px;
  }

  .services__title--big {
    font-size: 30px;
    margin-bottom: 3px;
  }

  &__tabs {
    max-width: 478px;
    width: 100%;
    background-color: var(--color-white-100);
    padding: 52px 35px;

    h2 {
      margin-bottom: 24px;
    }
  }


  [role="tablist"] {
    display: flex;
    flex-direction: column;
    gap: 23px;
  }

  [role="tab"] {
    position: relative;
    width: 100%;
    padding: 13px 40px 13px 20px;
    line-height: 1.27;
    font-size: 20px;
    background-color: transparent;
    text-align: left;
    transition: all 0.4s;

    &:hover,
    &:focus {
      background-color: var(--color-blue-400);
    }
    &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 20px;
      background-image: url('../images/icons/arrow-black.svg');
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
    }

  }

  button[aria-selected="true"] {
    background-color: var(--color-blue-400);

    &::after {
      background-image: url('../images/icons/arrow-white.svg');
    }
  }

  &__content {
    max-width: 688px;
    width: 100%;

    img {
      margin-bottom: 37px;
      aspect-ratio: 15 / 10;
    }

    p {
      color: var(--color-grey-400);

      &:first-of-type {
        margin-bottom: 42px;
      }

      &:nth-of-type(2) {
        margin-bottom: 40px;
      }
    }

    h3 {
      color: var(--color-blue-400);
      margin-bottom: 12px;
      font-size: 20px;
    }

  }

  &__benefits {
    display: flex;
    gap: 20px;

    img {
      max-width: 331px;
      width: 100%;
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 15px;
    }

    h3 {
      color: var(--color-green-350);
      margin-bottom: 21px;
    }
  }

  &__lists {

    li {
      padding-left: 35px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &::before {
        background-image: url('../images/icons/check.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 27px;
        height: 27px;
        background-color: unset;
      }
    }
  }

  @include media(tablet-wide) {

    &__benefits {
      flex-direction: column;

      img {
        max-width: unset;
        width: 100%;
      }
    }
  }

  @include media(tablet) {

    &__inner {
      flex-direction: column;
    }

    &__tabs {
      padding: 35px;
      max-width: unset;
    }

    &__content {
      max-width: unset;

      img {
        width: 100%;
      }
    }
  }

  @include media(mobile-l) {
    padding: 40px 0;
  }

  @include media(mobile-s) {

    .square {
      max-width: unset;
      text-align: left;

      &::before {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}