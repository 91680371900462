@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;

.protection {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  gap: 49px;

  img {
    max-width: 600px;
    aspect-ratio: 16 / 9;
  }

  &__content {
    padding: 57px 15px;

    h2 {
      margin-bottom: 24px;
      max-width: 492px;
    }

    p {

      &:first-child {
        padding-left: 17px;
        max-width: 482px;
        font-weight: 500;
        margin-bottom: 16px;
      }

      &:nth-of-type(2) {
        color: var(--color-grey-400);
        margin-bottom: 24px;
        max-width: 504px;
      }
    }
  }

  &__desc {
    padding-left: 36px;
    font-size: 15px;

    &:not(:last-of-type) {
      margin-bottom: 17px;
    }

    &:last-of-type {
      margin-bottom: 24px;
    }

    &::before {
      background-image: url('../images/icons/check.svg');
      width: 24px;
      height: 24px;
      background-color: unset;
    }
  }

  &__inner {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__item {
    max-width: 306px;
    padding: 24px 23px 24px 69px;
    border: 1px solid var(--color-white-175);

    h3 {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 15px;
      overflow: visible;
    }

    p {
      font-size: 12px;
      color: var(--color-grey-400);
    }

    &:first-child {

      h3 {

        &::before {
          background-image: url('../images/icons/quality.svg');
          width: 21px;
          height: 23px;
          background-color: unset;
          left: -37px;
        }

      }
    }

    &:last-child {

      h3 {

        &::before {
          background-image: url('../images/icons/integrity.svg');
          width: 29px;
          height: 21px;
          background-color: unset;
          left: -45px;
        }

      }
    }
  }

  .btn {
    max-width: 196px;
    background-color: var(--color-blue-400);
    color: var(--color-white);
  }

  @include media(desktop) {

    &__inner {
      flex-direction: column;
      gap: 20px;
    }

    &__item {
      max-width: unset;
      width: 100%;
    }
  }
  @include media(tablet) {
    flex-direction: column;
    gap: 40px;

    .btn {
      max-width: unset;
    }

    &__inner {
      flex-direction: row;
    }

    img {
      max-width: unset;
      width: 100%;
    }

    &__content {
      padding-top: 0;

      h2{
        max-width: unset;
      }

      p {
        &:nth-of-type(2) {
          max-width: unset;
        }
      }
    }
  }

  @include media(mobile-m) {

    .square{
      text-align: left;

      &::before {
        top: 13%;
        left: 0;
      }
    }

    &__inner {
      flex-direction: column;
    }

    &__content {
      margin-top: 0;

      h2{
        max-width: unset;
      }

      p {
        &:nth-of-type(2) {
          max-width: unset;
        }
      }
    }
  }
}