@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;

.hero {
  min-height: 982px;

  &--small{
    min-height: 647px;
  }

  &__inner {
    position: absolute;
    top: 167px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 815px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::before {
      content: '';
      position: absolute;
      width: 48%;
      height: 100%;
      background-color: var(--color-dark-400);
      top: 0;
      left: 0;
      z-index: 2;
    }

    &--small {
      min-height: 480px;

      .hero__content {
        padding-top: 0;
      }
      
      p {
        margin-bottom: 10px;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 33px;

    h1 {
      font-size: 60px;
      margin-bottom: 20px;
      max-width: 529px;
    }

    p {
      position: relative;
      text-align: center;

      &:last-of-type {
        margin-bottom: 35px;
        color: var(--color-white-200);
        font-weight: 500;
      }

      &:first-of-type {
        padding-left: 18px;
        margin-bottom: 10px;
        font-weight: 600;
        color: var(--color-white);
      }


    }

    .btn {
      max-width: 173px;
      justify-content: center;
      padding: 16px 32px;
      white-space: nowrap;
      background-color: var(--color-green-350);
      color: var(--color-brown-800);
      margin: 0;
      border: none;

      &:hover,
      &:focus {
        background-color: var(--color-blue-400);
        color: var(--color-white);
      }
    }
  }

  @include media(tablet) {
    min-height: 740px;

    &__inner {
      min-height: 615px;
      top: 125px;
    }
  }

  @include media(mobile-l) {
    min-height: calc(var(--header-height) + 400px);

    &__content {
      padding: 0;

      h1 {
        text-align: center;
        margin: 0 auto 20px;
        font-size: 34px;
      }

      p {
        margin: 0 auto 32px;
      }

      .btn {
        max-width: none;
        width: 100%;
      }
    }

    &__inner {
      min-height: 400px;
    }
  }

}



