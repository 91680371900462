@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;


.testimonals {
  padding: 86px 0;

  &__wrapper {
    display: flex;
    align-items: flex-start;
    gap: 37px;
    border-top: 1px solid var(--color-white-250);
    padding-top: 32px;
  }

  &__cards {
    display: flex;
    align-items: flex-start;
    gap: 37px;
  }

  &__title {
    max-width: 324px;
    width: 100%;

    h2 {
      margin-bottom: 24px;
    }

    p {
      color: var(--color-grey-400);

      &:first-of-type {
        padding-left: 17px;
        font-weight: 500;
        margin-bottom: 16px;
        color: var(--color-brown-800);
      }

    }
  }

  &__card {
    background-color: var(--color-white-125);
    padding: 50px 37px;
    max-width: 411px;

    p {
      color: var(--color-grey-400);
      font-style: italic;
      font-size: 22px;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 34px;

      h3 {
        font-size: 20px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        font-style: normal;
        line-height: 1.35;
      }
    }
  }

  @include media(tablet) {

    &__wrapper {
      flex-direction: column;
    }

    &__title {
      max-width: unset;
    }

    &__card {
      max-width: unset;
    }

  }

  @include media(mobile-l) {

    &__cards {
      flex-direction: column;
    }

    &__card-info {
      flex-direction: column;
      text-align: center;
    }

  }

  @include media(mobile-m) {
    padding: 40px 0;
  }

  @include media(mobile-s) {

    .square {
      text-align: left;

      &::before {
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
      
    }
  }
}