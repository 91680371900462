@use '../utils/functions/' as *;
@use '../utils/mixins/' as *;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  width: 100%;

  &__top {
    padding: 16px 0;
    background-color: var(--color-brown-800);
  }

  &__main {
    background-color: var(--color-white);
    padding: 32px 0;
    display: flex;
    align-items: center;
  }

  &__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      padding-left: 24px;
      transition: color 0.2s;

      &:hover,
      &:focus {
        color: var(--color-green-550);
      }
    }

    a, p {
      position: relative;
      color: var(--color-white);
      font-size: 14px;
      font-weight: 1.5;

      &::before {
        content: '';
        position: absolute;
        width: 13px;
        height: 10px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-image: url('../images/icons/envelope.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    p {
      
      &::before {
        content: '';
        left: -24px;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav__btns {

    .btn {
      font-size: 18px;
      padding: 16px 32px;
      margin: 0;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .btn--user {
      color: var(--color-blue-400);
      border: 1px solid var(--color-blue-400);
    }

    .request-btn {
      color: var(--color-white);
      background-color: var(--color-blue-400);

    }
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  @include media(tablet) {

    &__main {
      padding: 16px 0;
    }

  }

  @include media(mobile-m) {

    &__top {
      padding: 5px 0;
    }

    &__contact {
      flex-direction: column;
      gap: 10px;
    }

  }

  @include media(mobile-s) {

    .logo {
      max-width: 250px;
      width: 100%;
    }

  }
}
