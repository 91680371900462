@use '../utils/functions/' as *;
@use '../utils/mixins' as *;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  font-family: var(--font-family);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 1;
  letter-spacing: 0;
  background-color: var(--color-white);
  color: var(--color-brown-800);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

}

.main {
  
  &--top {
    padding-top: 50px;
  }
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
}


.logo {
  width: 100%;
  max-width: 319px;
}

.square {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: var(--color-green-350);
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  @include media(mobile-s) {
    max-width: 230px;
    text-align: center;

    &::before {
      top: 13%;
      left: 20%;
      transform: translate(0);
    }
  }
}





