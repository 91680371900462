@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;

.comprehensive {
  padding: 86px 0;

  .square {
    font-weight: 500;
    margin: 0 auto 16px;
    text-align: center;
    max-width: 400px;

    &::before {
      left: -18px;
    }
  }

  h2 {
    max-width: 658px;
    text-align: center;
    margin: 0 auto 40px;
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 27px 32px;
  }

  &__item {
    background-color: var(--color-white-100);
    padding: 30px;
    max-width: 592px;
    width: 100%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 160px;
      height: 150px;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: cover;
    }

    h3 {
      max-width: 204px;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.27;
      margin-bottom: 24px;
    }

    a {
      font-weight: 600;
      color: var(--color-blue-400);
      transition: color 0.2s;

      &:hover,
      &:focus {
        color: var(--color-green-350);
      }
    }

    &:nth-child(1) {

      &::after {
        content: '';
        background-image: url('../images/home/comprehensive.svg');
      }

    }

    &:nth-child(2) {

      &::after {
        content: '';
        background-image: url('../images/home/collision.svg');
      }

    }

    &:nth-child(3) {

      &::after {
        content: '';
        background-image: url('../images/home/liability.svg');
      }

    }

    &:nth-child(4) {

      h3 {
        max-width: 231px;
      }

      &::after {
        content: '';
        background-image: url('../images/home/additional.svg');
      }

    }
  }

  @include media(mobile-l) {

    &__inner {
      grid-template-columns: 1fr;
      justify-items: center;
    }

  }

  @include media(mobile-m) {
    padding: 40px 0;
  }

  @include media(mobile-s) {

    .square {
      max-width: 230px;
    }

    &__item {

      h3 {
        position: relative;
        z-index: 5;
      }

      &::after {
        z-index: 2;
        width: 121px;
        height: 113px;
      }
      
    }
  }



}


