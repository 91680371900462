@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;


.discover {
  padding: 86px 0;

  img {
    margin-bottom: 48px;
  }

  h2, h3 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  p {
    color: var(--color-grey-400);

    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }

  &__content {
    position: relative;
    padding: 90px 115px 66px;
    background-color: var(--color-green-400);
    margin-bottom: 48px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 24px;
      background-color: var(--color-blue-400);
    }

    p {
      text-align: center;
      font-size: 22px;
      font-style: italic;
      color: var(--color-brown-800);
    }
  }

  @include media(mobile-l) {

    &__content {
      padding: 60px;
    }

  }

  @include media(mobile-m) {
    padding: 40px 0;

    &__content {
      padding: 40px;
    }

  }
}