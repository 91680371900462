@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --header-height: 167px;
  --font-family: "Poppins", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-white-50: #f6f9fa;
  --color-white-100: #f4f4f4;
  --color-white-125: #f6f6f6;
  --color-white-150: #f8f8f8;
  --color-white-175: #e3e3e3;
  --color-white-200: #b9b9b9;
  --color-white-250: #c5c5c5;
  --color-black: #000;
  --color-dark-400: #37393fe8;
  --color-green-350: #40ddb6;
  --color-green-400: #40ddb633;
  --color-green-550: #268169;
  --color-blue-400: #6b78e5;
  --color-grey-400: #7d7d7d;
  --color-brown-800: #37393f;
  --radius-btn: 8px;
  --radius-main: 20px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  font-family: var(--font-family);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  background-color: var(--color-white);
  color: var(--color-brown-800);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1;
}

.page__body {
  min-height: var(--vh);
  min-width: 320px;
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}

.main--top {
  padding-top: 50px;
}

.site-container {
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.logo {
  width: 100%;
  max-width: 319px;
}

.square {
  position: relative;
}

.square:before {
  content: "";
  background-color: var(--color-green-350);
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media only screen and (width <= 475px) {
  .square {
    text-align: center;
    max-width: 230px;
  }

  .square:before {
    top: 13%;
    left: 20%;
    transform: translate(0);
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  width: 100%;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-700);
  letter-spacing: 0;
  color: var(--color-brown-800);
  width: fit-content;
  line-height: 1.21;
  overflow: hidden;
}

h1 {
  color: var(--color-white);
  font-size: clamp(2.25rem, 1.9951rem + 1.0458vi, 60px);
  line-height: 1.1;
}

@media only screen and (width <= 768px) {
  h1 {
    font-size: 34px;
  }
}

h2, .title {
  text-transform: capitalize;
  font-size: 40px;
  line-height: 1.27;
}

@media only screen and (width <= 768px) {
  h2, .title {
    font-size: 32px;
  }
}

h3 {
  width: 100%;
  font-size: 22px;
}

p {
  font-size: 18px;
  line-height: 1.5;
  font-weight: var(--fw-400);
  letter-spacing: 0;
}

.header {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__top {
  background-color: var(--color-brown-800);
  padding: 16px 0;
}

.header__main {
  background-color: var(--color-white);
  align-items: center;
  padding: 32px 0;
  display: flex;
}

.header__contact {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__contact a {
  padding-left: 24px;
  transition: color .2s;
}

.header__contact a:hover, .header__contact a:focus {
  color: var(--color-green-550);
}

.header__contact a, .header__contact p {
  color: var(--color-white);
  font-size: 14px;
  font-weight: 1.5;
  position: relative;
}

.header__contact a:before, .header__contact p:before {
  content: "";
  background-image: url("../images/icons/envelope.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 13px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.header__contact p:before {
  content: "";
  left: -24px;
}

.header .logo {
  justify-content: center;
  align-items: center;
  display: flex;
}

.header .nav__btns .btn {
  margin: 0;
  padding: 16px 32px;
  font-size: 18px;
}

.header .nav__btns .btn:not(:last-child) {
  margin-right: 8px;
}

.header .nav__btns .btn--user {
  color: var(--color-blue-400);
  border: 1px solid var(--color-blue-400);
}

.header .nav__btns .request-btn {
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

@media only screen and (width <= 992px) {
  .header__main {
    padding: 16px 0;
  }
}

@media only screen and (width <= 576px) {
  .header__top {
    padding: 5px 0;
  }

  .header__contact {
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (width <= 475px) {
  .header .logo {
    width: 100%;
    max-width: 250px;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (width <= 992px) {
  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__inner .nav__btns .btn--user:not(:last-child) {
    margin: 0 auto 10px;
  }
}

.nav__list {
  align-items: center;
  gap: 16px;
  margin-right: 24px;
  display: flex;
}

@media only screen and (width <= 1280px) {
  .header .nav__list {
    margin-right: 10px;
  }
}

@media only screen and (width <= 992px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__link {
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--color-brown-800);
  font-variation-settings: unset;
  background-color: #0000;
  border: 1px solid #0000;
  padding: 4px;
  font-size: 16px;
  line-height: 1.5;
  transition: color .4s, border .4s;
}

.nav__link:hover, .nav__link:focus, .nav__link.true {
  color: var(--color-blue-400);
}

.nav__btns {
  display: flex;
}

.nav__btns .btn--user {
  white-space: nowrap;
  color: var(--color-blue-400);
  border: 1px solid var(--color-blue-400);
  background-color: #0000;
  max-width: 133px;
}

.nav__btns .request-btn {
  white-space: nowrap;
  color: var(--color-white);
  background-color: var(--color-blue-400);
  max-width: 173px;
}

@media only screen and (width <= 992px) {
  .nav__btns {
    flex-direction: column;
  }

  .nav .nav__list {
    margin: 0 auto;
  }

  .nav .nav__item {
    margin-right: 0;
  }

  .nav--footer {
    flex-direction: column;
  }
}

@media only screen and (width <= 992px) and (width <= 576px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }
}

@media only screen and (width <= 992px) {
  .nav--footer .nav__inner {
    flex-direction: column;
    align-items: center;
  }
}

[class].btn {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  white-space: nowrap;
  text-align: center;
  color: inherit;
  background-color: inherit;
  border: 1px solid #0000;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 32px;
  font-size: 18px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-brown-800);
  color: var(--color-brown-800);
}

[class].btn--reverse {
  color: var(--color-brown-800);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  border-color: var(--color-brown-800);
  background-color: var(--color-white);
  color: var(--color-brown-800) !important;
}

[class].btn--user {
  position: relative;
}

[class].btn--user:hover:before, [class].btn--user:focus:before {
  filter: invert();
}

@media only screen and (width <= 475px) {
  [class].btn {
    min-width: auto;
    margin: 0 auto;
  }
}

[class].request-btn {
  position: relative;
}

[class].request-btn:hover:after, [class].request-btn:focus:after {
  filter: invert(0);
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-black);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  height: var(--line-height);
  background-color: var(--lines-color);
  width: 100%;
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (width <= 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  gap: 40px;
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  padding: calc(var(--header-height)  + 86px) 0 86px;
}

.policy__content {
  margin-bottom: 48px;
}

.policy__content:last-child {
  margin-bottom: 0;
}

.policy .square {
  text-align: center;
  margin: 0 auto 16px;
  font-weight: 500;
}

.policy .square:before {
  top: 50%;
  left: 44.5%;
  transform: translate(-50%, -50%);
}

.policy h1 {
  color: var(--color-brown-800);
  margin: 0 auto 48px;
}

.policy h2 {
  font-weight: var(--fw-600);
  margin: 0 0 24px;
  font-size: 25px;
}

.policy p {
  color: var(--color-grey-400);
}

.policy p:not(:last-child) {
  margin-bottom: 24px;
}

.policy a {
  color: var(--color-brown-800);
  font-weight: var(--fw-700);
  text-decoration: underline;
}

.policy ul {
  margin: 0 0 24px;
  padding-top: 24px;
}

.policy li {
  color: var(--color-grey-400);
  margin-bottom: 30px;
  font-size: 18px;
}

.policy li span {
  font-weight: 800;
}

.policy li:last-child {
  margin-bottom: 0;
}

@media only screen and (width <= 992px) {
  .policy {
    padding: calc(var(--header-height)  + 40px) 0 40px;
  }
}

@media only screen and (width <= 576px) {
  .policy h1 {
    font-size: 44px;
  }
}

@media only screen and (width <= 768px) {
  .policy h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer__bottom {
  background-color: var(--color-brown-800);
  padding: 25px 0;
}

.footer__main {
  background-color: var(--color-white);
  align-items: center;
  padding: 32px 0;
  display: flex;
}

.footer__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__inner p {
  color: var(--color-white);
  font-size: 16px;
}

.footer__inner a {
  color: var(--color-green-350);
  font-size: 16px;
  font-weight: 600;
}

.footer__inner a:not(:last-child) {
  margin-right: 48px;
}

.footer .nav__list {
  margin-right: 0;
}

.footer .nav__inner {
  gap: 20px;
}

.footer .logo {
  margin-bottom: 16px;
  display: block;
}

.footer .nav__btns .btn {
  margin: 0;
  padding: 16px 32px;
  font-size: 18px;
}

.footer .nav__btns .btn:not(:last-child) {
  margin-right: 8px;
}

.footer .nav__btns .btn--user {
  color: var(--color-blue-400);
  border: 1px solid var(--color-blue-400);
}

.footer .nav__btns .btn--user:hover, .footer .nav__btns .btn--user:focus {
  border-color: var(--color-brown-800);
  color: var(--color-brown-800);
}

.footer .nav__btns .request-btn {
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

.footer .nav__btns .request-btn:hover, .footer .nav__btns .request-btn:focus {
  border-color: var(--color-brown-800);
  color: var(--color-brown-800);
  background-color: var(--color-white);
}

.footer__policy a {
  transition: color .2s;
}

.footer__policy a:hover, .footer__policy a:focus {
  color: var(--color-green-550);
}

.footer__contact {
  font-size: 20px;
  transition: color .2s;
}

.footer__contact:hover, .footer__contact:focus {
  color: var(--color-blue-400);
}

.footer.hide {
  transform: translateY(calc(-100% - 30px));
}

@media only screen and (width <= 1180px) {
  .footer .nav__inner {
    flex-direction: column;
  }
}

@media only screen and (width <= 992px) {
  .footer .nav__inner .nav__btns {
    flex-direction: row;
  }

  .footer .nav__inner .nav__btns .btn:not(last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (width <= 768px) {
  .footer__contact-logo {
    text-align: center;
  }

  .footer .nav {
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
  }

  .footer__inner {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }

  .footer__inner p {
    text-align: center;
  }
}

@media only screen and (width <= 475px) {
  .footer__contact {
    font-size: 16px;
  }
}

.contact {
  padding: 86px 0 82px;
}

.contact__wrapper {
  gap: 24px;
  display: flex;
}

.contact__form {
  width: 100%;
  max-width: 588px;
}

.contact h2 {
  margin-bottom: 32px;
}

.contact label {
  margin-bottom: 16px;
  display: block;
}

.contact label:last-of-type {
  margin-bottom: 26px;
}

.contact input, .contact textarea {
  border: 1px solid var(--color-white-150);
  color: var(--color-brown-800);
  background-color: var(--color-white-50);
  width: 100%;
  padding: 17px;
  font-size: 16px;
}

.contact input::placeholder, .contact textarea::placeholder {
  color: var(--color-brown-800);
}

.contact textarea {
  resize: none;
  min-height: 156px;
}

.contact .btn {
  background-color: var(--color-green-350);
  max-width: none;
  padding-block: 16px;
  font-size: 18px;
  padding-inline: 16px !important;
}

.contact__info {
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 16px;
  display: flex;
}

.contact__info h3 {
  margin: 0 0 28px;
  font-size: 25px;
  font-weight: 600;
}

.contact__info-wrap {
  padding: 14px 0;
}

.contact__info a {
  color: var(--color-blue-400);
  margin-bottom: 28px;
  text-decoration: underline;
  display: block;
}

.contact__info a:hover, .contact__info a:focus {
  text-decoration: none;
}

.contact__title {
  color: var(--color-brown-800);
  text-align: left;
  margin-bottom: 28px;
  font-size: 25px;
  font-weight: 600;
}

.contact__time {
  color: var(--color-grey-400);
  max-width: 282px;
  font-size: 18px;
  line-height: 1.5;
}

@media only screen and (width <= 992px) {
  .contact {
    padding: 40px 0;
  }

  .contact__wrapper {
    flex-direction: column;
  }

  .contact__form {
    max-width: unset;
  }

  .contact__info-wrap {
    gap: 40px;
    display: flex;
  }
}

@media screen and (width <= 630px) {
  .contact__info-wrap {
    flex-direction: column;
    gap: 0;
  }
}

.hero {
  min-height: 982px;
}

.hero--small {
  min-height: 647px;
}

.hero__inner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 815px;
  display: flex;
  position: absolute;
  top: 167px;
  left: 0;
}

.hero__inner:before {
  content: "";
  background-color: var(--color-dark-400);
  z-index: 2;
  width: 48%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__inner--small {
  min-height: 480px;
}

.hero__inner--small .hero__content {
  padding-top: 0;
}

.hero__inner--small p {
  margin-bottom: 10px;
}

.hero__content {
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding-top: 33px;
  display: flex;
  position: relative;
}

.hero__content h1 {
  max-width: 529px;
  margin-bottom: 20px;
  font-size: 60px;
}

.hero__content p {
  text-align: center;
  position: relative;
}

.hero__content p:last-of-type {
  color: var(--color-white-200);
  margin-bottom: 35px;
  font-weight: 500;
}

.hero__content p:first-of-type {
  color: var(--color-white);
  margin-bottom: 10px;
  padding-left: 18px;
  font-weight: 600;
}

.hero__content .btn {
  white-space: nowrap;
  background-color: var(--color-green-350);
  color: var(--color-brown-800);
  border: none;
  justify-content: center;
  max-width: 173px;
  margin: 0;
  padding: 16px 32px;
}

.hero__content .btn:hover, .hero__content .btn:focus {
  background-color: var(--color-blue-400);
  color: var(--color-white);
}

@media only screen and (width <= 992px) {
  .hero {
    min-height: 740px;
  }

  .hero__inner {
    min-height: 615px;
    top: 125px;
  }
}

@media only screen and (width <= 768px) {
  .hero {
    min-height: calc(var(--header-height)  + 400px);
  }

  .hero__content {
    padding: 0;
  }

  .hero__content h1 {
    text-align: center;
    margin: 0 auto 20px;
    font-size: 34px;
  }

  .hero__content p {
    margin: 0 auto 32px;
  }

  .hero__content .btn {
    width: 100%;
    max-width: none;
  }

  .hero__inner {
    min-height: 400px;
  }
}

.comprehensive {
  padding: 86px 0;
}

.comprehensive .square {
  text-align: center;
  max-width: 400px;
  margin: 0 auto 16px;
  font-weight: 500;
}

.comprehensive .square:before {
  left: -18px;
}

.comprehensive h2 {
  text-align: center;
  max-width: 658px;
  margin: 0 auto 40px;
}

.comprehensive__inner {
  grid-template-columns: 1fr 1fr;
  gap: 27px 32px;
  display: grid;
}

.comprehensive__item {
  background-color: var(--color-white-100);
  width: 100%;
  max-width: 592px;
  padding: 30px;
  position: relative;
}

.comprehensive__item:after {
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  width: 160px;
  height: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.comprehensive__item h3 {
  max-width: 204px;
  margin-bottom: 24px;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.27;
}

.comprehensive__item a {
  color: var(--color-blue-400);
  font-weight: 600;
  transition: color .2s;
}

.comprehensive__item a:hover, .comprehensive__item a:focus {
  color: var(--color-green-350);
}

.comprehensive__item:first-child:after {
  content: "";
  background-image: url("../images/home/comprehensive.svg");
}

.comprehensive__item:nth-child(2):after {
  content: "";
  background-image: url("../images/home/collision.svg");
}

.comprehensive__item:nth-child(3):after {
  content: "";
  background-image: url("../images/home/liability.svg");
}

.comprehensive__item:nth-child(4) h3 {
  max-width: 231px;
}

.comprehensive__item:nth-child(4):after {
  content: "";
  background-image: url("../images/home/additional.svg");
}

@media only screen and (width <= 768px) {
  .comprehensive__inner {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media only screen and (width <= 576px) {
  .comprehensive {
    padding: 40px 0;
  }
}

@media only screen and (width <= 475px) {
  .comprehensive .square {
    max-width: 230px;
  }

  .comprehensive__item h3 {
    z-index: 5;
    position: relative;
  }

  .comprehensive__item:after {
    z-index: 2;
    width: 121px;
    height: 113px;
  }
}

.protection {
  gap: 49px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
}

.protection img {
  aspect-ratio: 16 / 9;
  max-width: 600px;
}

.protection__content {
  padding: 57px 15px;
}

.protection__content h2 {
  max-width: 492px;
  margin-bottom: 24px;
}

.protection__content p:first-child {
  max-width: 482px;
  margin-bottom: 16px;
  padding-left: 17px;
  font-weight: 500;
}

.protection__content p:nth-of-type(2) {
  color: var(--color-grey-400);
  max-width: 504px;
  margin-bottom: 24px;
}

.protection__desc {
  padding-left: 36px;
  font-size: 15px;
}

.protection__desc:not(:last-of-type) {
  margin-bottom: 17px;
}

.protection__desc:last-of-type {
  margin-bottom: 24px;
}

.protection__desc:before {
  background-image: url("../images/icons/check.svg");
  background-color: unset;
  width: 24px;
  height: 24px;
}

.protection__inner {
  gap: 16px;
  margin-bottom: 24px;
  display: flex;
}

.protection__item {
  border: 1px solid var(--color-white-175);
  max-width: 306px;
  padding: 24px 23px 24px 69px;
}

.protection__item h3 {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 600;
  overflow: visible;
}

.protection__item p {
  color: var(--color-grey-400);
  font-size: 12px;
}

.protection__item:first-child h3:before {
  background-image: url("../images/icons/quality.svg");
  background-color: unset;
  width: 21px;
  height: 23px;
  left: -37px;
}

.protection__item:last-child h3:before {
  background-image: url("../images/icons/integrity.svg");
  background-color: unset;
  width: 29px;
  height: 21px;
  left: -45px;
}

.protection .btn {
  background-color: var(--color-blue-400);
  color: var(--color-white);
  max-width: 196px;
}

@media only screen and (width <= 1280px) {
  .protection__inner {
    flex-direction: column;
    gap: 20px;
  }

  .protection__item {
    max-width: unset;
    width: 100%;
  }
}

@media only screen and (width <= 992px) {
  .protection {
    flex-direction: column;
    gap: 40px;
  }

  .protection .btn {
    max-width: unset;
  }

  .protection__inner {
    flex-direction: row;
  }

  .protection img {
    max-width: unset;
    width: 100%;
  }

  .protection__content {
    padding-top: 0;
  }

  .protection__content h2, .protection__content p:nth-of-type(2) {
    max-width: unset;
  }
}

@media only screen and (width <= 576px) {
  .protection .square {
    text-align: left;
  }

  .protection .square:before {
    top: 13%;
    left: 0;
  }

  .protection__inner {
    flex-direction: column;
  }

  .protection__content {
    margin-top: 0;
  }

  .protection__content h2, .protection__content p:nth-of-type(2) {
    max-width: unset;
  }
}

.testimonals {
  padding: 86px 0;
}

.testimonals__wrapper {
  border-top: 1px solid var(--color-white-250);
  align-items: flex-start;
  gap: 37px;
  padding-top: 32px;
  display: flex;
}

.testimonals__cards {
  align-items: flex-start;
  gap: 37px;
  display: flex;
}

.testimonals__title {
  width: 100%;
  max-width: 324px;
}

.testimonals__title h2 {
  margin-bottom: 24px;
}

.testimonals__title p {
  color: var(--color-grey-400);
}

.testimonals__title p:first-of-type {
  color: var(--color-brown-800);
  margin-bottom: 16px;
  padding-left: 17px;
  font-weight: 500;
}

.testimonals__card {
  background-color: var(--color-white-125);
  max-width: 411px;
  padding: 50px 37px;
}

.testimonals__card p {
  color: var(--color-grey-400);
  font-size: 22px;
  font-style: italic;
}

.testimonals__card-info {
  align-items: center;
  gap: 16px;
  margin-bottom: 34px;
  display: flex;
}

.testimonals__card-info h3 {
  font-size: 20px;
  font-weight: 600;
}

.testimonals__card-info p {
  font-size: 16px;
  font-style: normal;
  line-height: 1.35;
}

@media only screen and (width <= 992px) {
  .testimonals__wrapper {
    flex-direction: column;
  }

  .testimonals__title, .testimonals__card {
    max-width: unset;
  }
}

@media only screen and (width <= 768px) {
  .testimonals__cards {
    flex-direction: column;
  }

  .testimonals__card-info {
    text-align: center;
    flex-direction: column;
  }
}

@media only screen and (width <= 576px) {
  .testimonals {
    padding: 40px 0;
  }
}

@media only screen and (width <= 475px) {
  .testimonals .square {
    text-align: left;
  }

  .testimonals .square:before {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.services {
  padding: 86px 0;
}

.services__inner {
  align-items: flex-start;
  gap: 50px;
  display: flex;
}

.services h2 {
  text-transform: none;
  max-width: 688px;
  font-size: 25px;
  font-weight: 600;
}

.services .services__title--big {
  margin-bottom: 3px;
  font-size: 30px;
}

.services__tabs {
  background-color: var(--color-white-100);
  width: 100%;
  max-width: 478px;
  padding: 52px 35px;
}

.services__tabs h2 {
  margin-bottom: 24px;
}

.services [role="tablist"] {
  flex-direction: column;
  gap: 23px;
  display: flex;
}

.services [role="tab"] {
  text-align: left;
  background-color: #0000;
  width: 100%;
  padding: 13px 40px 13px 20px;
  font-size: 20px;
  line-height: 1.27;
  transition: all .4s;
  position: relative;
}

.services [role="tab"]:hover, .services [role="tab"]:focus {
  background-color: var(--color-blue-400);
}

.services [role="tab"]:after {
  content: "";
  background-image: url("../images/icons/arrow-black.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
}

.services button[aria-selected="true"] {
  background-color: var(--color-blue-400);
}

.services button[aria-selected="true"]:after {
  background-image: url("../images/icons/arrow-white.svg");
}

.services__content {
  width: 100%;
  max-width: 688px;
}

.services__content img {
  aspect-ratio: 15 / 10;
  margin-bottom: 37px;
}

.services__content p {
  color: var(--color-grey-400);
}

.services__content p:first-of-type {
  margin-bottom: 42px;
}

.services__content p:nth-of-type(2) {
  margin-bottom: 40px;
}

.services__content h3 {
  color: var(--color-blue-400);
  margin-bottom: 12px;
  font-size: 20px;
}

.services__benefits {
  gap: 20px;
  display: flex;
}

.services__benefits img {
  width: 100%;
  max-width: 331px;
  margin-bottom: 0;
}

.services__benefits h2 {
  margin-bottom: 15px;
}

.services__benefits h3 {
  color: var(--color-green-350);
  margin-bottom: 21px;
}

.services__lists li {
  padding-left: 35px;
}

.services__lists li:not(:last-child) {
  margin-bottom: 30px;
}

.services__lists li:before {
  background-image: url("../images/icons/check.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: unset;
  width: 27px;
  height: 27px;
}

@media only screen and (width <= 1180px) {
  .services__benefits {
    flex-direction: column;
  }

  .services__benefits img {
    max-width: unset;
    width: 100%;
  }
}

@media only screen and (width <= 992px) {
  .services__inner {
    flex-direction: column;
  }

  .services__tabs {
    max-width: unset;
    padding: 35px;
  }

  .services__content {
    max-width: unset;
  }

  .services__content img {
    width: 100%;
  }
}

@media only screen and (width <= 768px) {
  .services {
    padding: 40px 0;
  }
}

@media only screen and (width <= 475px) {
  .services .square {
    max-width: unset;
    text-align: left;
  }

  .services .square:before {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.discover {
  padding: 86px 0;
}

.discover img {
  margin-bottom: 48px;
}

.discover h2, .discover h3 {
  margin-bottom: 16px;
  font-weight: 600;
}

.discover p {
  color: var(--color-grey-400);
}

.discover p:not(:last-of-type) {
  margin-bottom: 48px;
}

.discover__content {
  background-color: var(--color-green-400);
  margin-bottom: 48px;
  padding: 90px 115px 66px;
  position: relative;
}

.discover__content:before {
  content: "";
  background-color: var(--color-blue-400);
  width: 100%;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.discover__content p {
  text-align: center;
  color: var(--color-brown-800);
  font-size: 22px;
  font-style: italic;
}

@media only screen and (width <= 768px) {
  .discover__content {
    padding: 60px;
  }
}

@media only screen and (width <= 576px) {
  .discover {
    padding: 40px 0;
  }

  .discover__content {
    padding: 40px;
  }
}

.team {
  padding: 86px 0;
}

.team .square {
  max-width: 423px;
  margin: 0 auto 16px;
  font-weight: 500;
}

.team .square:before {
  left: -19px;
}

.team h2 {
  text-align: center;
  max-width: 658px;
  margin: 0 auto 64px;
}

.team__items {
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  display: grid;
}

.team__item {
  max-width: 384px;
  position: relative;
}

.team__item img {
  aspect-ratio: 16 / 22;
  width: 100%;
}

.team__description {
  background-color: var(--color-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 344px;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.team__description h3 {
  text-align: center;
  margin-bottom: 4px;
  font-size: 25px;
  font-weight: 600;
}

.team__description p {
  color: var(--color-grey-400);
}

@media only screen and (width <= 992px) {
  .team__items {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .team__item:last-child {
    grid-column: 1 / -1;
  }
}

@media only screen and (width <= 768px) {
  .team {
    padding: 40px 0;
  }

  .team__items {
    grid-template-columns: 1fr;
  }
}

@media only screen and (width <= 475px) {
  .team .square {
    max-width: 230px;
  }
}

.faq {
  padding: calc(var(--header-height)  + 86px) 0 86px;
}

.faq .square {
  text-align: center;
  max-width: 230px;
  margin: 0 auto 16px;
  font-weight: 500;
}

.faq .square:before {
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.faq h1 {
  color: var(--color-brown-800);
  text-align: center;
  margin: 0 auto 48px;
}

.faq__item {
  border: 2px solid var(--color-white-175);
  padding: 24px 88px 24px 32px;
  position: relative;
}

.faq__item:not(:last-child) {
  margin-bottom: 16px;
}

.faq__item div p {
  color: var(--color-grey-400);
}

.faq details[open] summary:before {
  display: none;
}

.faq details[open] summary + div {
  padding: 12px 0 10px;
  position: relative;
}

.faq details[open] summary + div:before {
  content: "";
  background-image: url("../images/icons/arrow-purple.svg");
  width: 18px;
  height: 11px;
  position: absolute;
  top: 30%;
  right: -48px;
  overflow: hidden;
  transform: translateY(-50%)rotate3d(0, 0, 1, 0);
}

.faq summary {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
}

.faq summary:before {
  content: "";
  background-image: url("../images/icons/arrow-purple.svg");
  width: 18px;
  height: 11px;
  position: absolute;
  top: 50%;
  right: -48px;
  transform: translateY(-50%)rotate3d(0, 0, 1, 180deg);
}

@media only screen and (width <= 768px) {
  .faq {
    padding: calc(var(--header-height)  + 40px) 0 40px;
  }
}

@media only screen and (width <= 475px) {
  .faq summary, .faq p {
    font-size: 14px;
  }
}
/*# sourceMappingURL=main.css.map */
