/* stylelint-disable declaration-no-important */
@use '../utils/functions/' as *;
@use '../utils/mixins' as *;

*[class].btn {
  font-family: var(--font-family-primary);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 16px 32px;
  width: 100%;
  font-weight: var(--fw-500);
  font-size: 18px;
  white-space: nowrap;
  line-height: 1;
  text-align: center;
  color: inherit;
  background-color: inherit;
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s;

  &:hover,
  &:focus {
    border-color: var(--color-brown-800);
    color: var(--color-brown-800);
  }

  &--reverse {
    color: var(--color-brown-800);

    &:hover,
    &:focus {
      border-color: var(--color-brown-800);
      color: var(--color-brown-800) !important;
      background-color: var(--color-white);
    }
  }

  &--user {
    position: relative;

    &:hover::before,
    &:focus::before {
      filter: invert(1);
    }
  }

  @include media(mobile-s) {
    margin: 0 auto;
    min-width: auto;
  }
}

*[class].request-btn {
  position: relative;

  &:hover::after,
  &:focus::after {
    filter: invert(0);
  }
}

