@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  padding: 86px 0 82px;

  &__wrapper {
    display: flex;
    gap: 24px;
  }

  &__form {
    max-width: 588px;
    width: 100%;
  }

  h2 {
    margin-bottom: 32px;
  }

  label {
    display: block;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 26px;
    }
    
  }

  input,
  textarea {
    border: 1px solid var(--color-white-150);
    padding: 17px;
    width: 100%;
    color: var(--color-brown-800);
    background-color: var(--color-white-50);
    font-size: 16px;

    &::placeholder {
      color: var(--color-brown-800);
    }

  }

  textarea {
    min-height: 156px;
    resize: none;
  }

  .btn {
    max-width: none;
    padding-block: 16px;
    padding-inline: 16px !important;
    font-size: 18px;
    background-color: var(--color-green-350);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 16px;

    h3 {
      font-size: 25px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 28px;
    }

    &-wrap {
      padding: 14px 0;
    }

    a {
      display: block;
      color: var(--color-blue-400);
      text-decoration: underline;
      margin-bottom: 28px;

      &:hover,
      &:focus {
        text-decoration: none;
      }

    }
  }


  &__title {
    color: var(--color-brown-800);
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 28px;
    text-align: left;
  }

  &__time {
    max-width: 282px;
    font-size: 18px;
    line-height: 1.5;
    color: var(--color-grey-400);
  }

  @include media(tablet) {
    padding: 40px 0;

    &__wrapper {
      flex-direction: column;
    }

    &__form {
      max-width: unset;
    }

    &__info-wrap {
      display: flex;
      gap: 40px;
    }

  }

}

@media screen and (max-width: 630px) {

  .contact__info-wrap {
    flex-direction: column;
    gap: 0;
  }

}