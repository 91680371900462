/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');


:root {
  --content-width: 1216px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  --header-height: 167px;

  // font-family
  --font-family: "Poppins", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-white-50: #f6f9fa;
  --color-white-100: #f4f4f4;
  --color-white-125: #f6f6f6;
  --color-white-150: #f8f8f8;
  --color-white-175: #e3e3e3;
  --color-white-200: #b9b9b9;
  --color-white-250: #c5c5c5;

  --color-black: #000;
  
  --color-dark-400: rgba(55, 57, 63, 0.91);

  --color-green-350: #40ddb6;
  --color-green-400: rgba(64, 221, 182, 0.2);
  --color-green-550: #268169;


  --color-blue-400: #6b78e5;


  --color-grey-400: #7d7d7d;

  --color-brown-800: #37393f;

  //  radius
  --radius-btn: 8px;
  --radius-main: 20px;

}


